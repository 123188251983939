import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import { withSEO } from 'components/_hoc';
import Login from 'components/Login';

const LOGIN_URL = '/teachers/user/login';

/**
 * Teacher login page
 */
const TeacherLoginPage: FC<{}> = () => {
  const [isVerifying, setIsVerifying] = useState<boolean>(true);

  useEffect((): void => {
    if (window.localStorage.getItem('teacher.isAuthenticated') === 'true') {
      navigate('/teacher/home');
    } else {
      setIsVerifying(false);
    }
  }, []);

  return isVerifying ? null : <Login page="teacher" url={LOGIN_URL} />;
};

export default withSEO(TeacherLoginPage, {
  title: 'Login • Teacher',
  description:
    'Teacher login for Einstein Studios. Sign in to manage schedules, set availabilities, and give feedback to students.',
  pathname: '/login/teacher',
});
